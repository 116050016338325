// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Catalogs from './catalogs/reducers';

export default (combineReducers({
    Auth,
    Layout,
    Catalogs
}): any);
