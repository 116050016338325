// @flow
import { APICore } from './apiCore';
import { SERVER } from '../../constants';

const api = new APICore();

const _PATH = {
  GET_OCR_DATA: '/ADM01/getOCRData',
  GET_REQUESTS: '/ops/getRequests',
  CREATE_NEW_CLIENT: '/ADM01/createNewClient',
  VALIDATE_INE: '/ADM01/validateINE',
  GET_PENDING_REGISTRATIONS: '/ADM01/getPendingRegistrations',
  GET_TRACING_FLUJO: '/ops/getTracingFlujo',
  GET_FECHAS_RESUMEN: '/ops/getFechasResumen',
  GET_USER_CURP: '/ops/getUserCurp',
  GET_USER_FILES: '/ops/getUserFiles',
  GET_COMBO_ESTADOS: '/ops/getComboEstados',
  SET_ESTADO: '/ops/setEstado',
  GET_USER_DATA: '/ops/getUserData',
  GET_USER_ADDRESS: '/ops/getUserAddress',
  GET_DOCUMENTOS_LEGALES: '/ops/getDocumentosLegales',
  GET_DOCUMENTOS_LEGALES_FIRMADOS: '/ops/getDocumentosLegalesFirmados',
  GET_SOLICITUDES_PEPSICO: '/ops/getSolicitudesPepsico',
  GET_SOLICITUDES_PEPSICO_DIRECCIONES: '/ops/getSolicitudesPepsicoDirecciones',
  CONFIRMA_PRESTAMO: '/ops/confirmaPrestamo',
  EVENTO_EVIDENCIA_PEPSICO: '/ops/eventoEvidenciaPepsico',
  GET_DISPERSIONES_MANUALES: '/ops/getDispersionesManuales',
  CONFIRMAR_DISPERSION: '/ops/confirmarDispersion',
}

const apiConfirmarDispersion = (params) => api.create(`${SERVER}${_PATH.CONFIRMAR_DISPERSION}`, params);
const apiGetDispersionesManuales = (params) => api.create(`${SERVER}${_PATH.GET_DISPERSIONES_MANUALES}`, params);
const apiEventoEvidenciaPepsico = (params) => api.createWithFile(`${SERVER}${_PATH.EVENTO_EVIDENCIA_PEPSICO}`, params);
const apiConfirmaPrestamo = (params) => api.create(`${SERVER}${_PATH.CONFIRMA_PRESTAMO}`, params);
const apiGetSolicitudesPepsicoDirecciones = (params) => api.create(`${SERVER}${_PATH.GET_SOLICITUDES_PEPSICO_DIRECCIONES}`, params);
const apiGetSolicitudesPepsico = (params) => api.create(`${SERVER}${_PATH.GET_SOLICITUDES_PEPSICO}`, params);
const apiGetDocumentosLegales = (params) => api.create(`${SERVER}${_PATH.GET_DOCUMENTOS_LEGALES}`, params);
const apiGetDocumentosLegalesFirmados = (params) => api.create(`${SERVER}${_PATH.GET_DOCUMENTOS_LEGALES_FIRMADOS}`, params);

function getUserAddress(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_USER_ADDRESS}`, params);
}

function getUserData(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_USER_DATA}`, params);
}

function apiSetEstado(params: any): any {
  return api.create(`${SERVER}${_PATH.SET_ESTADO}`, params);
}

function getComboEstados(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_COMBO_ESTADOS}`, params);
}

function getUserFiles(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_USER_FILES}`, params);
}

function getUserCurp(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_USER_CURP}`, params);
}

function getFechasResumen(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_FECHAS_RESUMEN}`, params);
}

function getTracingFlujo(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_TRACING_FLUJO}`, params);
}

function getRequests(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_REQUESTS}`, params);
}

function getPendingRegistrations(params: any): any {
  console.log("Parametros: ", params);
  return api.create(`${SERVER}${_PATH.GET_PENDING_REGISTRATIONS}`, params);
}

function getOCRData(params: any): any {
  console.log("Parametros: ", params);
  return api.createWithFile(`${SERVER}${_PATH.GET_OCR_DATA}`, params);
}

function createNewClient(params: any): any {
  console.log("Parametros: ", params);
  return api.create(`${SERVER}${_PATH.CREATE_NEW_CLIENT}`, params);
}

function validateINE(params: any): any {
  console.log("Parametros: ", params);
  return api.create(`${SERVER}${_PATH.VALIDATE_INE}`, params);
}

export {
  getOCRData,
  createNewClient,
  getRequests,
  validateINE,
  getPendingRegistrations,
  getTracingFlujo,
  getFechasResumen,
  getUserCurp,
  getUserFiles,
  getComboEstados,
  apiSetEstado,
  getUserData,
  getUserAddress,
  apiGetDocumentosLegales,
  apiGetDocumentosLegalesFirmados,
  apiGetSolicitudesPepsico,
  apiGetSolicitudesPepsicoDirecciones,
  apiConfirmaPrestamo,
  apiEventoEvidenciaPepsico,
  apiGetDispersionesManuales,
  apiConfirmarDispersion,
};