// @flow
import { ActionTypes } from './constants';
import { CatalogsKeys } from './catalogKeys';

const INIT_STATE = {
    c_oficinas: JSON.parse(localStorage.getItem(CatalogsKeys.OFICINAS)),
    c_tiposID: JSON.parse(localStorage.getItem(CatalogsKeys.TIPOS_ID)),
    c_estadoCivil: JSON.parse(localStorage.getItem(CatalogsKeys.ESTADO_CIVIL)),
    c_tiposDocumentos: JSON.parse(localStorage.getItem(CatalogsKeys.TIPOS_DOCUMENTOS)),
    loading: false,
};

type Action = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = {
    c_oficinas?: {} | null,
    c_tiposID?: {} | null,
    c_estadoCivil?: {} | null,
    c_tiposDocumentos?: {} | null,
    loading?: boolean, +value ?: boolean 
};

const Catalogs = (state: State = INIT_STATE, action: Action): any => {
    switch (action.type) {
        case ActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ActionTypes.GET_C_OFICINAS: {
                    return {
                        ...state,
                        c_oficinas: action.payload.data,
                        loadOficinas: true,
                        loading: false,
                    };
                }
                case ActionTypes.GET_C_TIPOS_ID: {
                    return {
                        ...state,
                        c_tiposID: action.payload.data,
                        loadTiposID: true,
                        loading: false,
                    };
                }
                case ActionTypes.GET_C_ESTADO_CIVIL: {
                    return {
                        ...state,
                        c_estadoCivil: action.payload.data,
                        loadEstadoCivil: true,
                        loading: false,
                    };
                }
                case ActionTypes.GET_C_TIPOS_DOCUMENTOS: {
                    return {
                        ...state,
                        c_tiposDocumentos: action.payload.data,
                        loadTiposDocumentos: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ActionTypes.GET_C_OFICINAS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loadOficinas: false,
                        loading: false,
                    };
                }
                case ActionTypes.GET_C_TIPOS_ID: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loadTiposID: false,
                        loading: false,
                    };
                }
                case ActionTypes.GET_C_ESTADO_CIVIL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loadEstadoCivil: false,
                        loading: false,
                    };
                }
                case ActionTypes.GET_C_TIPOS_DOCUMENTOS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loadTiposDocumentos: false,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ActionTypes.GET_C_OFICINAS:
            return { ...state, loading: true, loadOficinas: false };
        case ActionTypes.GET_C_TIPOS_ID:
            return { ...state, loading: true, loadTiposID: false };
        case ActionTypes.GET_C_ESTADO_CIVIL:
            return { ...state, loading: true, loadEstadoCivil: false };
        case ActionTypes.GET_C_TIPOS_DOCUMENTOS:
            return { ...state, loading: true, loadTiposDocumentos: false };
        default:
            return { ...state };
    }
};

export default Catalogs;
