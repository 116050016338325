// @flow
import React from 'react';
import Routes from './routes/Routes';
import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

// For Saas import Saas.scss
import './assets/scss/Saas.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';

/**
 * Main app component
 */

const App = (): React$Element<any> => {
    return <Routes />;
};

export default App;
