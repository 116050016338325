// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { CatalogsKeys } from './catalogKeys';
import {
    //loadCatalogs
    getCOficinas,
    getCTiposID,
    getCEstadoCivil,
    getCTiposDocumentos
} from '../../helpers/';

import { apiResponseSuccess, apiResponseError } from './actions';
import { ActionTypes } from './constants';



function* getCatalogOficinas() {
    try {
        const response = yield call(getCOficinas);
        const data = response.data.data;
        localStorage.setItem(CatalogsKeys.OFICINAS, JSON.stringify(data));
        yield put(apiResponseSuccess(ActionTypes.GET_C_OFICINAS, data));
    } catch (error) {
        yield put(apiResponseError(ActionTypes.GET_C_OFICINAS, error));
    }
}
function* getCatalogTiposID() {
    try {
        const response = yield call(getCTiposID);
        const data = response.data.data;
        localStorage.setItem(CatalogsKeys.TIPOS_ID, JSON.stringify(data));
        yield put(apiResponseSuccess(ActionTypes.GET_C_TIPOS_ID, data));
    } catch (error) {
        yield put(apiResponseError(ActionTypes.GET_C_TIPOS_ID, error));
    }
}
function* getCatalogTiposDocumentos() {
    try {
        const response = yield call(getCTiposDocumentos);
        const data = response.data.data;
        localStorage.setItem(CatalogsKeys.TIPOS_DOCUMENTOS, JSON.stringify(data));
        yield put(apiResponseSuccess(ActionTypes.GET_C_TIPOS_DOCUMENTOS, data));
    } catch (error) {
        yield put(apiResponseError(ActionTypes.GET_C_TIPOS_DOCUMENTOS, error));
    }
}
function* getCatalogEstadoCivil() {
    try {
        const response = yield call(getCEstadoCivil);
        const data = response.data.data;
        localStorage.setItem(CatalogsKeys.ESTADO_CIVIL, JSON.stringify(data));
        yield put(apiResponseSuccess(ActionTypes.GET_C_ESTADO_CIVIL, data));
    } catch (error) {
        yield put(apiResponseError(ActionTypes.GET_C_ESTADO_CIVIL, error));
    }
}

export function* watchGetCatalogOficinas(): any {
    yield takeEvery(ActionTypes.GET_C_OFICINAS, getCatalogOficinas);
}
export function* watchGetCatalogTiposID(): any {
    yield takeEvery(ActionTypes.GET_C_OFICINAS, getCatalogTiposID);
}
export function* watchGetCatalogEstadoCivil(): any {
    yield takeEvery(ActionTypes.GET_C_ESTADO_CIVIL, getCatalogEstadoCivil);
}
export function* watchGetCatalogTiposDocumentos(): any {
    yield takeEvery(ActionTypes.GET_C_TIPOS_DOCUMENTOS, getCatalogTiposDocumentos);
}

function* catalogsSaga(): any {
    yield all([fork(watchGetCatalogOficinas), fork(watchGetCatalogTiposID), fork(watchGetCatalogEstadoCivil), fork(watchGetCatalogTiposDocumentos)]);
}

export default catalogsSaga;
