export const ActionTypes = {
    API_RESPONSE_SUCCESS: '@@catalogs/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@catalogs/API_RESPONSE_ERROR',

    GET_C_OFICINAS: '@@catalogs/GET_C_OFICINAS',
    GET_C_TIPOS_ID: '@@catalogs/GET_C_TIPOS_ID',
    GET_C_ESTADO_CIVIL: '@@catalogs/GET_C_ESTADO_CIVIL',
    GET_C_TIPOS_DOCUMENTOS: '@@catalogs/GET_C_TIPOS_DOCUMENTOS',

    RESET: '@@catalogs/RESET',
};
