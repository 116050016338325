import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
//Requiere verificador por Perfil
//TODO: Podría agregarse la ruta en un sp en la BD
const Root = () => {
  const { user } = useSelector((state) => ({
    user: state.Auth.user,
  }));
  const getRootUrl = () => {
    if (user && user.perfilG) {
      switch (user.perfilG) {
        case 'MESA': return 'creditos/solicitudes'; break;
        case 'PEPSICO': return 'creditos/solicitudespepsico'; break;
        case 'PROMO': return 'capacitacion'; break;
        default: return 'creditos/solicitudes'; break;
      }
    }
    return 'creditos/solicitudes'
  };

  const url = getRootUrl();
  return <Navigate to={`/${url}`} />;
};

export default Root;