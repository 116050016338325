// @flow
import { APICore } from './apiCore';
import { SERVER } from '../../constants';

const api = new APICore();

const _PATH = {
    LOGIN: '/public/login/',
    DECODE_JWT: '/public/decodeJWT/'
}


// account
function login(params: any): any {
    //return api.create('/login/', params);
    return api.create(`${SERVER}${_PATH.LOGIN}`, params);
}

function logout(): any {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: any): any {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: any): any {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

const decodeJWT = (params) => api.create(`${SERVER}${_PATH.DECODE_JWT}`, params);

export { login, logout, signup, forgotPassword, forgotPasswordConfirm, decodeJWT };
