// @flow
import { ActionTypes } from './constants';

type Action = { type: string, payload: {} | string };

// common success
export const apiResponseSuccess = (actionType: string, data: any): Action => ({
    type: ActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const apiResponseError = (actionType: string, error: string): Action => ({
    type: ActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCatalogOficinas = (): Action => ({
    type: ActionTypes.GET_C_OFICINAS,
    payload: {},
});
export const getCatalogTiposID = (): Action => ({
    type: ActionTypes.GET_C_TIPOS_ID,
    payload: {},
});
export const getCatalogEstadoCivil = (): Action => ({
    type: ActionTypes.GET_C_ESTADO_CIVIL,
    payload: {},
});
export const getCatalogTiposDocumentos = (): Action => ({
    type: ActionTypes.GET_C_TIPOS_DOCUMENTOS,
    payload: {},
});
export const reset = (): Action => ({
    type: ActionTypes.RESET,
    payload: {},
});