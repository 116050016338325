import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';
import { ACCESS_ROL } from '../constants';
import { readJsonConfigFile } from 'typescript';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

//creditos
const Documentos = React.lazy(() => import('../pages/creditos/Documentos'));
const InvRegistro = React.lazy(() => import('../pages/creditos/Registro'));
const InvSolicitudes = React.lazy(() => import('../pages/creditos/Solicitudes'));
const SolicitudesPepsico = React.lazy(() => import('../pages/creditos/Solicitudes/SolicitudesPepsico'));
const Profile = React.lazy(() => import('../pages/creditos/Perfil'));
const ClientData = React.lazy(() => import('../pages/creditos/Perfil/ClientData'));
const DispersionesManuales = React.lazy(() => import('../pages/creditos/DispersionesManuales'));

// pages
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// - other
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const GuestLogin = React.lazy(() => import('../pages/other/GuestLogin'));
const Starter = React.lazy(() => import('../pages/other/Starter'));
const PreLoader = React.lazy(() => import('../pages/other/PreLoader'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));

//Capacitacion
const Capacitacion = React.lazy(() => import('../pages/capacitacion'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { layout, user } = useSelector((state) => ({
        layout: state.Layout,
        user: state.Auth.user
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        if (user && user.perfilG == 'PROMO') layoutCls = DefaultLayout;
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'guest-login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
                {
                    path: 'guest-login',
                    element: <LoadComponent component={GuestLogin} />,
                },
                {
                    path: 'guest-login/:token',
                    element: <LoadComponent component={GuestLogin} />
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
        {
            //Rutas PEPSICO
            path: '/',
            element: <PrivateRoute roles={ACCESS_ROL.PEPSICO} component={Layout} />,
            children: [
                {
                    path: 'creditos',
                    children: [
                        {
                            path: 'solicitudesPepsico',
                            element: <LoadComponent component={SolicitudesPepsico} />
                        },
                    ]
                }
            ]
        },
        {
            //Rutas MESA
            path: '/',
            element: <PrivateRoute roles={ACCESS_ROL.MESA} component={Layout} />,
            children: [
                {
                    path: 'creditos',
                    children: [
                        {
                            path: 'documentos',
                            element: <LoadComponent component={Documentos} />
                        },
                        {
                            path: 'registro',
                            element: <LoadComponent component={InvRegistro} />
                        },
                        {
                            path: 'solicitudes',
                            element: <LoadComponent component={InvSolicitudes} />
                        },
                        {
                            path: 'solicitudes/:idProceso',
                            element: <LoadComponent component={Profile} />
                        },
                        {
                            path: 'solicitudes/:idProceso/:codUsuario',
                            element: <LoadComponent component={ClientData} />
                        },
                        {
                            path: 'perfil',
                            element: <LoadComponent component={Profile} />
                        }
                    ]
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'starter',
                            element: <LoadComponent component={Starter} />,
                        },
                        {
                            path: 'pricing',
                            element: <LoadComponent component={Pricing} />,
                        },
                        {
                            path: 'error-404-alt',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                        {
                            path: 'timeline',
                            element: <LoadComponent component={Timeline} />,
                        },
                        {
                            path: 'invoice',
                            element: <LoadComponent component={Invoice} />,
                        },
                        {
                            path: 'faq',
                            element: <LoadComponent component={FAQ} />,
                        },
                        {
                            path: 'preloader',
                            element: <LoadComponent component={PreLoader} />,
                        },
                    ],
                },
            ],
        },
        {
            //Rutas TESOSERIA
            path: '/',
            element: <PrivateRoute roles={ACCESS_ROL.TESORERIA} component={Layout} />,
            children: [
                {
                    path: 'creditos',
                    children: [
                        {
                            path: 'dispersionesmanuales',
                            element: <LoadComponent component={DispersionesManuales} />
                        },
                    ]
                }
            ]
        },
        {
            //Ruta CAPACITACION
            path: '/',
            element: <PrivateRoute roles={ACCESS_ROL.CAPACITACION} component={Layout} />,
            children: [
                {
                    path: 'capacitacion',
                    element: <LoadComponent component={Capacitacion} />
                }
            ]
        },
       

    ]);
};

export { AllRoutes };
