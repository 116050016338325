//export const SERVER = 'http://localhost:1025'; //LOCAL
export const SERVER = 'https://finamigo-creditos-backend-oalpaabn6q-ue.a.run.app'; //PROD
export const CURRENT_VERSION = '1.1.8';

export const ACCESS_ROL = {
  MESA: ['ADMIN', 'MESA'],
  PEPSICO: ['ADMIN', 'PEPSICO'],
  TESORERIA: ['ADMIN', 'TESORERIA'],
  CAPACITACION:['ADMIN','PROMO']
};

export const IDS_DOCS_LEGALES_PEPSICO = [81, 82, 83, 84, 85, 86, 87, 88, 89, 90]