// @flow
import { APICore } from './apiCore';
import { SERVER } from '../../constants';

const api = new APICore();

const _PATH = {
  GET_C_OFICINAS: '/ops/getCOficinas',
  GET_C_TIPOS_ID: '/ops/getCTiposID',
  GET_C_ESTADO_CIVIL: '/ops/getCEstadoCivil',
  GET_C_TIPOS_DOCUMENTOS: '/ops/getCTiposDocumentos',
}

function getCOficinas(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_C_OFICINAS}`, params);
}
function getCTiposID(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_C_TIPOS_ID}`, params);
}
function getCEstadoCivil(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_C_ESTADO_CIVIL}`, params);
}
function getCTiposDocumentos(params: any): any {
  return api.create(`${SERVER}${_PATH.GET_C_TIPOS_DOCUMENTOS}`, params);
}

export { getCOficinas, getCTiposID, getCEstadoCivil, getCTiposDocumentos };