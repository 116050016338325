import moment from 'moment';
import 'moment-timezone';
import numeral from 'numeral';

export * from './array';
export * from './layout';

moment.locale('es-mx');
moment.tz.setDefault("America/Mexico_City");

const applyUTC = (date) => {
  return moment(date).utc()
}

const getItemCatalog = ({ arrayCatalog, idName, valueName, value }) => {
  if(!arrayCatalog || !idName || !valueName || !value) return '-'
  const item = arrayCatalog.find(e => e[idName] === value)
  return item[valueName]
}

const formatDate = (date) => {
  if (!date) return '-'
  date = applyUTC(date)
  return moment(date).format('DD MMM, YYYY')
};
const formatDateHrs = (date) => {
  if (!date) return '-'
  date = applyUTC(date)
  return moment(date).format('DD-MM-YY HH:mm:ss')
};
function formatCurrency(num) {
  if (!num) return '-'
  return numeral(num).format('$ 0,0.00')
}
const formatCurrencyPorcentaje = (num) => {
  if (!num) return '-'
  return numeral(num).format('0.0')
}

const smallDateTimeFormat = (date) => {
  if (!date) return '-'
  date = applyUTC(date)
  return moment(date).format('YYYY-MM-DD 00:00:00')
};

export {
  formatDate, formatDateHrs, formatCurrency, formatCurrencyPorcentaje, smallDateTimeFormat,
  getItemCatalog
};